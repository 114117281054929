import React, {FC} from 'react'

interface TermsProps {
}

const Terms: FC<TermsProps> = () => {
    return (
        <>

            <div className="container mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title d-flex justify-content-start align-items-center">
                            <div className="title-icon">
                                <i className="bi bi-exclamation-diamond"></i>
                            </div>
                            <div className="title-content">
                                <h1>Terms</h1>
                                <p>Read the terms of use</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="modal-content">
                            <div className="card card-style-2">
                                <div className="disclaimer-body">
                                    <h5> Hydranet / Hydranet Off-Chain LTC. Educational Calculator Disclaimer</h5>
                                    <p>
                                        Please read this disclaimer carefully before using the Hydranet and Hydranet
                                        Off-Chain LTC
                                        Educational Calculators (hereinafter referred to as "the Calculators") provided
                                        by
                                        Hydranet / Hydranet
                                        Off-Chain LTC.
                                    </p>
                                    <p>
                                        <b>Educational Purpose Only:</b>
                                        The Calculators have been developed for educational purposes only. They are
                                        designed
                                        to help users
                                        understand how decentralized autonomous organizations (DAOs) operate and how
                                        revenue
                                        allocation
                                        works within such entities. The Calculators do not constitute financial,
                                        investment,
                                        or legal advice.
                                    </p>
                                    <p>
                                        <b>No Promise of Returns:</b>
                                        The use of the Calculators does not imply or guarantee any returns on
                                        investment,
                                        financial gains, or
                                        revenue-sharing agreements. The Calculators are purely theoretical models and
                                        should
                                        not be
                                        interpreted as a promise or assurance of any kind regarding financial outcomes.
                                    </p>
                                    <p>
                                        <b>No Investment Recommendation:</b>
                                        The Calculators do not provide investment recommendations or strategies. Users
                                        should not make any
                                        financial decisions based solely on the results or information provided by the
                                        Calculators. Any
                                        decision to invest or participate in DAOs should be made independently and after
                                        conducting thorough
                                        research and seeking appropriate advice.
                                    </p>
                                    <p>
                                        <b>Not a Financial Product:</b>
                                        The Calculators are not a financial product, security, or investment instrument.
                                        They do not
                                        constitute an offer to sell or buy any tokens, assets, or financial instruments.
                                        Users should
                                        exercise caution and due diligence when considering any financial transactions.
                                    </p>
                                    <p>
                                        <b>No Liability:</b>
                                        Hydranet / Hydranet Off-Chain LTC. and its developers, contributors, and
                                        affiliates
                                        shall not be
                                        held liable for any direct or indirect losses, damages, or consequences
                                        resulting
                                        from the use or
                                        misuse of the Calculators, including but not limited to financial losses or
                                        legal
                                        disputes.
                                    </p>
                                    <p>
                                        <b>Consultation with Professionals:</b>
                                        Users are strongly encouraged to seek advice from financial, legal, and tax
                                        professionals before
                                        making any investment decisions or participating in DAOs.
                                    </p>
                                    <p>
                                        <b>Disclaimer Changes:</b>
                                        Hydranet / Hydranet Off-Chain LTC. reserves the right to modify or update this
                                        disclaimer at any
                                        time without prior notice. Users are responsible for reviewing this disclaimer
                                        regularly for any
                                        changes.
                                    </p>
                                    <p>
                                        By using the Calculators, you acknowledge that you have read, understood, and
                                        agreed
                                        to this
                                        disclaimer. If you do not agree with any part of this disclaimer, please refrain
                                        from using the
                                        Calculators.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}

export default Terms;
