import {create} from "zustand";
import {Bridge, BridgeService, Data} from "../services/BridgeService";

interface BridgeStore {
    loading: boolean,
    service: BridgeService,
    bridges: Bridge[],
    fetch: (refresh?: boolean) => Promise<Bridge[]>
}

export const useBridgeStore = create<BridgeStore>()((set, get) => ({
    loading: false,
    service: new BridgeService(),
    bridges: [],
    fetch: async (refresh?: boolean): Promise<Bridge[]> => {
        let {bridges, loading, service} = get();
        let fetchedBridges: Data | null = null;
        if (!loading) {
            if (bridges.length <= 0 || refresh) {
                fetchedBridges = await service.fetchBridges();
                set({bridges: fetchedBridges.bridges, loading: false})
            }
        }
        return Promise.resolve(fetchedBridges?.bridges || []);
    },
}))
