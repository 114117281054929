import React, {FC, useEffect, useState} from 'react'
import {BridgeService} from "../services/BridgeService";
import _ from "lodash";

interface DashboardProps {
}

const Dashboard: FC<DashboardProps> = () => {

    const bridgeService = new BridgeService()
    const [address, setAddress] = useState<string>('')
    const [bridgeResp, setBridgeResp] = useState<any>(null)
    const [error, setError] = useState('')

    return (
        <>
            <div className="container mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title d-flex justify-content-start align-items-center">
                            <div className="title-icon">
                                <i className="bi bi-person-workspace"></i>
                            </div>
                            <div className="title-content">
                                <h1>Bridge Calculator</h1>
                                <p>Save fees using the Hydranet Web DEX</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bridge-calculator">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="card card-style-2">
                                <div className="card-heading">
                                    <h2>Wallet Address</h2>

                                </div>
                                <h3 className="d-none">Revenue:</h3>

                                <div className="user-box">
                                    <label className='d-none'>Enter your wallet address

                                    </label>
                                    <input type="text" name="addressInput" step="100" id="addressInput"
                                           placeholder='Enter your wallet address' value={address}
                                           onChange={(event) => {
                                               setAddress(event?.target?.value)
                                           }}></input>
                                </div>
                                <button className="button button-primary" onClick={() => {
                                    if (address.length > 0) {
                                        bridgeService.fetchUserWallet(address.trim()).then(resp => {
                                            if (resp) {
                                                setBridgeResp(resp);
                                                if (!resp?.txCount) {
                                                    setError('It appears that no bridging transactions were detected. Please try a different address.')
                                                } else {
                                                    setError('')
                                                }
                                            } else {
                                                setBridgeResp(null)
                                            }
                                        })
                                    }
                                }}>Calculate Now
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-12 mb-4">
                            <div className="card card-style-2">
                                <div className="card-heading">
                                    <h2>Results</h2>
                                </div>
                                {
                                    error.length > 0 ?
                                        <div className="alert alert-danger" role="alert">
                                            {error}
                                        </div> :
                                        <div className="user-box results">
                                            <p>With Hydranet Web DEX you could have
                                                saved: {_.round(bridgeResp?.idealTxFeeSavingUsd || 0, 2).toLocaleString()} USD</p>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="result-box">
                                                        <h4>Bridge
                                                            Transactions:</h4> {_.round(bridgeResp?.txCount || 0, 2).toLocaleString()}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="result-box">
                                                        <h4>Total Value
                                                            Bridged:</h4> {_.round(bridgeResp?.totalBridgedValueUsd || 0, 2).toLocaleString()} USD
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="result-box">
                                                        <h4>Total Blockchain Fees
                                                            Paid:</h4> {_.round(bridgeResp?.totalTxFeeUsd || 0, 2).toLocaleString()} USD
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="result-box">
                                                        <h4>You could have
                                                            saved:</h4> {_.round(bridgeResp?.idealTxFeeSavingUsd || 0, 2).toLocaleString()} USD
                                                        {/* <h4>You could have saved: USD amount:</h4> {_.round(bridgeResp?.hydranetTotalTxFeeUsd || 0, 2).toLocaleString()} USD */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Dashboard;
