import React, {FC} from 'react'

interface FaqsProps {
}

const Faqs: FC<FaqsProps> = () => {

    return (
        <>
            <div className="container mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title d-flex justify-content-start align-items-center">
                            <div className="title-icon">
                                <i className="bi bi-question-octagon"></i>
                            </div>
                            <div className="title-content">
                                <h1>FAQs</h1>
                                <p>Read frequently asked questions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='section-faqs'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="accordion faq-accordion" id="faqsAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#tab-01" aria-expanded="true" aria-controls="tab-01">
                                            What is a blockchain bridge?

                                        </button>
                                    </h2>
                                    <div id="tab-01" className="accordion-collapse collapse show">
                                        <div className="accordion-body">
                                            <p>
                                                A blockchain bridge is a solution linking together separate blockchains,
                                                making it possible to transfer funds between them. Traditional
                                                blockchain bridges typically facilitate the transfer of funds using
                                                smart contracts running on both participating blockchains. Hydranet
                                                solves this differently.

                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-02" aria-expanded="false" aria-controls="tab-02">
                                            What makes Hydranet different?

                                        </button>
                                    </h2>
                                    <div id="tab-02" className="accordion-collapse collapse">
                                        <div className="accordion-body">

                                            Hydranet combines off-chain protocols to facilitate bridging of funds
                                            between separate blockchains. The exchange of funds (the bridging) is
                                            settled off-chain, instead of using smart contracts making Hydranet cheaper
                                            and more secure.


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-03" aria-expanded="false" aria-controls="tab-03">
                                            How can Hydranet be cheaper?

                                        </button>
                                    </h2>
                                    <div id="tab-03" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            Moving funds off-chain comes at a lower gas cost than using traditional
                                            on-chain bridges.
                                            Once funds are off-chain, swapping them is virtually feeless.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-04" aria-expanded="false" aria-controls="tab-04">
                                            When can I use the Hydranet DEX to bridge funds?

                                        </button>
                                    </h2>
                                    <div id="tab-04" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <p>
                                            The Hydranet Web DEX is under development. Make sure to follow Hydranet’s official channels to get the latest updates!

                                            </p>

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-05" aria-expanded="false" aria-controls="tab-05">
                                           Why is my wallet address not showing any results?

                                        </button>
                                    </h2>
                                    <div id="tab-05" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                     <p> If entering your wallet address does not give any results it’s because: </p>  
                                     <ul>
<li>The blockchain bridge you used cannot be found in our dataset</li>
<li>The direction you bridged cannot be found in our dataset</li>
<li>You made your bridge transaction prior to 2019 or after the latest data synchronization</li>
<li>The Hydranet Web DEX is not cheaper for your specific bridge transaction(s)</li>
</ul>
<p>
Refer to “Which chains are included?“ and “What bridges can be found in your dataset?“ to find what blockchain bridges and bridge directions are included in our dataset.
</p> 
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-06" aria-expanded="false" aria-controls="tab-6">
                                            Why are the results not showing all my transactions?

                                        </button>
                                    </h2>
                                    <div id="tab-06" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                        If you notice that the number of 'Bridge Transactions' displayed is lower than what you expect, it may be because the Hydranet Web DEX is not cheaper for all your conducted bridge transactions. 

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-07" aria-expanded="false" aria-controls="tab-7">
                                            Which chains are included in your dataset? 

                                        </button>
                                    </h2>
                                    <div id="tab-07" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                        Ethereum, Arbitrum, Polygon, Binance Smart Chain, Optimism, Zk-Sync
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-08" aria-expanded="false" aria-controls="tab-8">
                                           What bridges can be found in your dataset?
                                        </button>
                                    </h2>
                                    <div id="tab-08" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                        Across, Arbitrum bridge, Celer, Hop, Optimism bridge, Stargate, Wormhole, and zkSync bridge
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tab-09" aria-expanded="false" aria-controls="tab-9">
                                           What bridge directions can be found in your dataset?
                                        </button>
                                    </h2>
                                    <div id="tab-09" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                        <p>Our dataset includes bridge directions between Ethereum - sidechains only. Bridge transactions between sidechains, such as transactions between Polygon and Arbitrum, are not included.</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faqs;

