import React, {FC, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import Header from '../components/header';
import {ToastContainer} from "react-toastify";
import {useBridgeStore} from "../stores/bridgeStore";

interface LayoutProps {
}

const Layout: FC<LayoutProps> = () => {

    const bridgeStore = useBridgeStore();


    useEffect(() => {
        bridgeStore.fetch(true).then(bridges => {

        })
    }, []);

    return (
        <>
            <ToastContainer/>
            <Header></Header>
            <main className='main py-5'>
                <Outlet/>
            </main>
        </>
    )
};

export default Layout;
