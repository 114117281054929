import React from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom"
import Dashboard from './pages/dashboard';
import Layout from './pages/layout';
import Faqs from './pages/faqs';
import Terms from './pages/terms';
import './App.css';
import './icons.css';


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index={true} element={<Dashboard/>}/>
                    <Route path='faqs' element={<Faqs/>}/>
                    <Route path='terms' element={<Terms/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
