import React, { Component } from 'react'
import logo from '../assets/logo.svg'
import { NavLink } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'

const Desktop = ({children}: any) => {
    const isDesktop = useMediaQuery({minWidth: 1180})
    return isDesktop ? children : null
}

const Mobile = ({children}: any) => {
    const isMobile = useMediaQuery({maxWidth: 1179})
    return isMobile ? children : null
}

export default class header extends Component {
  render() {
    return (
        <header>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-3 col-6'>
            <NavLink to="/">
              <img src={logo} alt='logo'></img>
            </NavLink>

          </div>
          <div className='col-lg-9 col-6'>
            <Desktop>
              <div className='d-flex justify-content-end align-items-center'>


                <ul className='main-nav navbar'>
                  <li className='nav-item'>
                    <NavLink to="/">DASHBOARD</NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to="faqs">FAQs</NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to="terms">TERMS</NavLink>
                  </li>
                </ul>
    
                <a href='https://hydranet.ai' className='button back-home ms-4'>BACK HOME</a>
              </div>
            </Desktop>

            <Mobile>
              <div className='d-flex justify-content-end'>
                <button className="menu-control" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">

                </button>
              </div>
            </Mobile>
          </div>
        </div>
      </div>
      <button className="btn btn-primary d-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
        x
      </button>

      <div className="offcanvas offcanvas-start offcanvas-menu" data-bs-scroll="true" tab-index="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasMenuLabel">Menu</h5>
          <button type="button" className="close-button" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
        </div>
        <div className="offcanvas-body">

          <ul className='mobile-nav navbar'>
          <li className='nav-item' data-bs-dismiss="offcanvas">
              <NavLink to="/">DASHBOARD</NavLink>
            </li>
            <li className='nav-item' data-bs-dismiss="offcanvas">
              <NavLink to="faqs">FAQs</NavLink>
            </li>
            <li className='nav-item' data-bs-dismiss="offcanvas">
              <NavLink to="terms">TERMS</NavLink>
            </li>

          </ul>

          <a href='https://hydranet.ai' className='button back-home text-center w-100 d-block'>BACK HOME</a>

        </div>
      </div>

    </header>
    )
  }
}
