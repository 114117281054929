import {AxiosResponse} from "axios";
import apiClient from "../http/apiClient";
import {BaseService} from "./BaseService";

export interface Bridge {
    id: number;
    name: string;
    lastIndexedBlockHeight: number;
    includeInResponse: boolean;
}

export interface Data {
    bridges: Bridge[];
}

export interface UserWallet {
    lastUpdatedAt: string;
    address: string;
    idealTxFeeSavingUsd: number;
    txCount: number;
    hydranetTxCount: number;
    totalBridgedValueUsd: number;
    hydranetBridgedValueUsd: number;
    totalTxFeeUsd: number;
    hydranetTotalTxFeeUsd: number;
}

export class BridgeService extends BaseService {
    constructor(props?: any) {
        super(props);

    }

    async fetchBridges(): Promise<Data> {
        try {
            let response: AxiosResponse = await apiClient.get(`${process.env.REACT_APP_BASE_URL}/Bridges`);
            if (response.status === 200) {
                return response.data as Data;
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async fetchUserWallet(address: string): Promise<UserWallet | false> {
        try {
            let response: AxiosResponse = await apiClient.get(`${process.env.REACT_APP_BASE_URL}/Users/${address}`);
            if (response.status === 200) {
                return response.data as UserWallet;
            }
        } catch (e) {
            return Promise.resolve(false);
        }
        return Promise.resolve(false);
    }

}
